import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectGallery from "../components/ui/ProjectGallery/ProjectGallery";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import { Images } from "../utils/findImage";

import projectPageData from "../content/pages/projects.json";

interface ProjectsPageProps {
  pageContext: {};
  data: {
    allImages: Images;
  };
}

const ProjectsPage: React.FC<ProjectsPageProps> = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO
        title={projectPageData.seo.seoTitle}
        description={projectPageData.seo.metaDescription}
        slug="projects"
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white mb-0 leading-none">Projects</h1>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ">
            <SectionIntro
              subHeading="Our work"
              heading="Explore our recent projects"
              className="col-span-full text-center mb-8 justify-self-center"
            >
              <p className="max-w-lg mx-auto">
                No matter how big or small, we approach every project with the
                same attention to detail.
              </p>
            </SectionIntro>
          </div>
          <ProjectGallery>
            {data.allImages.nodes.map((item, index) => (
              <div key={`portfolio item-${index}`} className="mb-4">
                <GatsbyImage
                  image={item.childImageSharp.gatsbyImageData}
                  alt="Portfolio image"
                />
              </div>
            ))}
          </ProjectGallery>
        </div>
      </section>
    </Layout>
  );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query($projectGallery: [String!]) {
    allImages: allFile(filter: { base: { in: $projectGallery } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
      }
    }
  }
`;
